import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import { gapi$, gapiInit$, User, UserContext, logout, login } from './auth';
import { green } from '@material-ui/core/colors';
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Theme,
  List,
  Drawer,
  Divider,
  CssBaseline,
  CircularProgress,
  createStyles,
  Snackbar,
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { EmojiPeople, FlashAuto, Close } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Alert,
} from '@material-ui/lab';
import { useResponsive } from 'react-hooks-responsive';

import {
  BrowserRouter,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { ListItemLink } from './ListItemLink';
import { Tasks } from './Tasks';
import { Actions } from './Actions';
import { People } from './People';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';
import { Notif, NotifContext } from './notif';
import { wait, useAsyncCallback } from './utils';

if (process.env.NODE_ENV !== 'production') {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}
firebase.firestore().enablePersistence({ synchronizeTabs: true });

const drawerWidth = 240;
const breakpoints = { xs: 0, sm: 480, md: 1024 };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    main: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDialAction: {
      fontSize: 30,
    },
    email: {
      fontSize: 10,
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    },
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <Home />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </div>
  );
};

const Home: React.FC = () => {
  const classes = useStyles();
  const { size, orientation, screenIsAtLeast, screenIsAtMost } = useResponsive(
    breakpoints
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [disableLogin, setDisableLogin] = useState(true);
  const history = useHistory();
  const [user, setUser] = useState<User>({});
  useEffect(() => {
    gapiInit$.then(() => {
      setDisableLogin(false);
    });
  }, []);
  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(user => {
        const newUser: User = {};
        if (user) {
          Object.assign(newUser, user);
          newUser.ref = firebase
            .firestore()
            .collection('users')
            .doc(user.uid);
          newUser.actions = newUser.ref.collection('actions') as any;
          newUser.people = newUser.ref.collection('people') as any;
          newUser.homes = newUser.ref.collection('homes') as any;
          newUser.tasks = newUser.ref.collection('tasks') as any;
        }
        setUser(newUser);
      }),
    []
  );

  useEffect(() => history.listen(() => setDrawerOpen(false)), []);
  return (
    <UserContext.Provider value={user}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {screenIsAtLeast('md') ? (
            ''
          ) : (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(true)}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            {screenIsAtLeast('md') ? 'Awesome Butler' : 'Butler'}
          </Typography>
          {user.email && <div className={classes.email}>{user.email}</div>}
          {user.email ? (
            <Button color="inherit" onClick={logout}>
              LOGOUT
            </Button>
          ) : (
            <Button color="inherit" onClick={login} disabled={disableLogin}>
              LOGIN
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <MyDrawer
        open={drawerOpen || screenIsAtLeast('md')}
        variant={screenIsAtLeast('md') ? 'permanent' : 'temporary'}
        closeDrawer={() => setDrawerOpen(false)}
      />
      <main className={classes.main}>
        <div className={classes.toolbar} />
        {user.email && <Content />}
      </main>
    </UserContext.Provider>
  );
};

const MyDrawer: React.FC<{
  open: boolean;
  variant: 'permanent' | 'temporary';
  closeDrawer: () => void;
}> = ({ open, variant, closeDrawer }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useContext(UserContext);
  const route = user.uid ? location.pathname : '/';
  return (
    <Drawer
      open={open}
      variant={variant}
      className={classes.drawer}
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItemLink
          to="/people"
          primary="People"
          icon={<EmojiPeople />}
          selected={route.startsWith('/people')}
        />
      </List>
      <Divider />
      <List>
        <ListItemLink
          to="/actions"
          primary="Actions"
          icon={<FlashAuto />}
          selected={route.startsWith('/actions')}
        />
      </List>
      <Divider />
      <List>
        <ListItemLink
          to="/tasks"
          primary="Tasks"
          icon={<InboxIcon />}
          selected={route.startsWith('/tasks')}
        />
      </List>
    </Drawer>
  );
};

const Content: React.FC = () => {
  const classes = useStyles();
  const [refreshRunning, refresh] = useAsyncCallback(
    firebase.functions().httpsCallable('refresh')
  );
  const [resetRunning, reset] = useAsyncCallback(
    firebase.functions().httpsCallable('reset')
  );
  const [dialOpen, setDialOpen] = React.useState(false);
  const [notif, setNotif] = React.useState<Notif | undefined>(undefined);
  const [notifEnd, setNotifEnd] = React.useState<(() => void) | undefined>(
    undefined
  );

  function endNotif() {
    if (notifEnd) {
      setNotifEnd(undefined);
      notifEnd();
    }
  }

  function closeNotif() {
    setNotif(undefined);
    endNotif();
  }

  async function notifContext(n: Notif) {
    if (notif) throw new Error('Another action in progress');
    setNotif(n);
    return new Promise<void>(setNotifEnd);
  }

  const undo = notif && notif.undo;
  const undoNotif = undo
    ? () => {
        setNotif({ msg: 'Undoing ...', severity: 'info' });
        Promise.all([wait(500), undo!()]).then(() => {
          setNotif({ msg: 'Undone !' });
          endNotif();
        });
      }
    : undefined;

  return (
    <div className={classes.content}>
      <NotifContext.Provider value={notifContext}>
        <Switch>
          <Route path="/tasks">
            <Tasks />
          </Route>
          <Route path="/people">
            <People />
          </Route>
          <Route path="/actions">
            <Actions />
          </Route>
          <Route path="/"></Route>
        </Switch>
      </NotifContext.Provider>

      <SpeedDial
        ariaLabel="speed dial actions"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        open={dialOpen || resetRunning || refreshRunning}
        onOpen={() => setDialOpen(true)}
        onClose={() => setDialOpen(false)}
      >
        <SpeedDialAction
          FabProps={{ disabled: resetRunning || refreshRunning }}
          icon={
            <div>
              <DeleteIcon />
              {resetRunning && (
                <CircularProgress size={40} className={classes.fabProgress} />
              )}
            </div>
          }
          tooltipTitle="Reset"
          tooltipOpen
          onClick={reset}
          className={classes.speedDialAction}
        />
        <SpeedDialAction
          FabProps={{ disabled: resetRunning || refreshRunning }}
          icon={
            <div>
              <SaveIcon />
              {refreshRunning && (
                <CircularProgress size={40} className={classes.fabProgress} />
              )}
            </div>
          }
          tooltipTitle="Refresh"
          tooltipOpen
          onClick={refresh}
          className={classes.speedDialAction}
        />
      </SpeedDial>
      <Snackbar
        open={notif !== undefined}
        autoHideDuration={6000}
        onClose={closeNotif}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={notif?.severity || 'success'}
          action={
            <div>
              {undoNotif && (
                <Button color="inherit" size="small" onClick={undoNotif}>
                  Undo
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                title="close"
                color="inherit"
                onClick={closeNotif}
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
          }
        >
          {notif?.msg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default App;
