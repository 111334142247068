import React from 'react';
import { DateTime } from 'luxon';

export function wait(timeout: number): Promise<void> {
  return new Promise(res => setTimeout(res, timeout));
}

export function renderISODate(v: string, def?: string): string | undefined {
  return v ? DateTime.fromISO(v).toFormat('dd/MM/yyyy') : def;
}

export function useAsyncCallback<T>(
  cb: () => Promise<T>
): [boolean, () => Promise<T>] {
  const [isPending, setIsPending] = React.useState(false);
  return [
    isPending,
    async () => {
      setIsPending(true);
      try {
        return await cb();
      } finally {
        setIsPending(false);
      }
    },
  ];
}
