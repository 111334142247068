import React, { useContext, useState } from 'react';
import {
  useParams,
  useRouteMatch,
  Redirect,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { UserContext } from './auth';
import { useQuery } from './hooks';
import { DateTime } from 'luxon';
import {
  TaskActionDismiss,
  TaskActionSnooze,
  Task,
} from '../../shared/src/model';
import { Autocomplete } from '@material-ui/lab';
import {
  TextField,
  MenuItem,
  makeStyles,
  Theme,
  createStyles,
  Button,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Add } from '@material-ui/icons';
import MaterialTable from 'material-table';
import { NotifContext, Notif } from './notif';
import { renderISODate } from './utils';

const refreshTasks = () =>
  firebase
    .functions()
    .httpsCallable('refresh')({ do_tasks: true })
    .then(() => {});

function notifAdd<T>(msg: string, setNotif: (n: Notif) => Promise<void>) {
  return (dr: firebase.firestore.DocumentReference<T>) =>
    setNotif({
      msg,
      undo: () => dr.delete(),
    });
}

const DismissTask: React.FC = () => {
  const { taskUid } = useParams();
  const user = useContext(UserContext);
  const setNotif = useContext(NotifContext);

  const query = useQuery();
  const all = query.get('all');
  const date = DateTime.local().toISODate();
  const action = {
    type: 'task_dismiss',
    task_id: taskUid,
    date,
  } as TaskActionDismiss;
  if (all === 'true') action.all = true;
  user
    .actions!.add(action)
    .then(
      notifAdd(
        action.all ? 'Task silenced' : 'Task occurence dismissed',
        setNotif
      )
    )
    .then(refreshTasks);

  return <Redirect to="/" />;
};

const SnoozeTask: React.FC = () => {
  const { taskUid } = useParams();
  const user = useContext(UserContext);
  const setNotif = useContext(NotifContext);
  const query = useQuery();
  const until = query.get('until');
  const date = DateTime.local().toISODate();
  const action = {
    type: 'task_snooze',
    task_id: taskUid,
    date,
    until,
  } as TaskActionSnooze;
  user
    .actions!.add(action)
    .then(notifAdd(`Task snoozed for ${until}`, setNotif))
    .then(refreshTasks);

  return <Redirect to="/" />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formField: {
      //    display: 'block',
      width: 300,
      marginBottom: 30,
    },
  })
);

const AddTask: React.FC = () => {
  // 1. select category | custom
  // 2. select task | custom
  // 3. select start | now
  // 4. select frequency | once
  // 5. select ETA | ASAP
  const history = useHistory();
  const user = useContext(UserContext);
  const classes = useStyles();
  const [frequency, setFrequency] = useState<string>('none');
  const [category, setCategory] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<DateTime | null>(DateTime.local());
  const [etaDate, setEtaDate] = useState<DateTime | null>(null);
  const getTask = () => {
    const task = {
      type: category,
      summary: name,
      start: startDate!.toISODate(),
    } as Task;
    if (frequency !== 'none') task.freq = parseInt(frequency);
    if (etaDate) task.eta = etaDate.toISODate();
    return task;
  };
  return (
    <div>
      <Autocomplete<string>
        id="category"
        className={classes.formField}
        freeSolo
        options={[
          'auto-entrepreneur',
          'pole-emploi',
          'creche',
          'elections',
          'maternelle',
          'mutuelle',
          'menage',
          'impots',
        ]}
        onInputChange={(event: React.ChangeEvent<{}>, value: string | null) =>
          setCategory(value)
        }
        renderInput={params => (
          <TextField
            {...params}
            label="Category"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Autocomplete
        id="task"
        className={classes.formField}
        freeSolo
        options={[]}
        onInputChange={(event: React.ChangeEvent<{}>, value: string | null) =>
          setName(value)
        }
        renderInput={params => (
          <TextField {...params} label="Task" variant="outlined" fullWidth />
        )}
      />
      <DatePicker
        id="start"
        className={classes.formField}
        autoOk
        format="dd/MM/yyyy"
        inputVariant="outlined"
        label="Start Date"
        value={startDate}
        onChange={setStartDate}
      />
      <br />
      <TextField
        id="frequency"
        className={classes.formField}
        select
        value={frequency}
        variant="outlined"
        label="Frequency"
        onChange={e => setFrequency(e.target.value)}
      >
        <MenuItem value="none">NONE</MenuItem>
        <MenuItem value="0">YEARLY</MenuItem>
        <MenuItem value="1">MONTHLY</MenuItem>
        <MenuItem value="2">WEEKLY</MenuItem>
        <MenuItem value="3">DAILY</MenuItem>
      </TextField>
      <br />
      <DatePicker
        id="ETA"
        className={classes.formField}
        autoOk
        clearable
        clearLabel="ASAP"
        emptyLabel="ASAP"
        format="dd/MM/yyyy"
        minDate={startDate}
        minDateMessage="Date should not be before start date"
        label="ETA"
        inputVariant="outlined"
        value={etaDate}
        onChange={setEtaDate}
      />
      <br />
      <Button
        className={classes.formField}
        variant="contained"
        color="secondary"
        disabled={!category || !name}
        startIcon={<Add />}
        onClick={() => {
          user.tasks?.add(getTask()).then(refreshTasks);
          history.push('/');
        }}
      >
        Add task
      </Button>
    </div>
  );
};

const UnsnoozeTask: React.FC = () => {
  const { taskUid } = useParams();
  const user = useContext(UserContext);
  const setNotif = useContext(NotifContext);
  user
    .actions!.where('task_id', '==', taskUid)
    .get()
    .then(({ docs }) => Promise.all(docs.map(it => it.ref.delete())))
    .then(() =>
      setNotif({
        msg: 'Task unsnoozed',
      })
    )
    .then(refreshTasks);
  return <Redirect to="/" />;
};

const TaskList: React.FC = () => {
  const user = useContext(UserContext);
  return (
    <MaterialTable<Task & { id: string }>
      options={{ search: false, paging: false }}
      title="All tasks"
      columns={[
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Category', field: 'type' },
        { title: 'Name', field: 'summary' },
        {
          title: 'Start',
          field: 'start',
          type: 'date',
          render: ({ start }) => renderISODate(start as string),
          editComponent: ({ value, onChange }) => (
            <DatePicker
              autoOk
              format="dd/MM/yyyy"
              value={value}
              onChange={v => onChange(v!.toISODate())}
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          ),
        },
        {
          title: 'Frequency',
          field: 'freq',
          lookup: {
            0: 'YEARLY',
            1: 'MONTHLY',
            2: 'WEEKLY',
            3: 'DAILY',
          },
        },
        {
          title: 'ETA',
          field: 'eta',
          type: 'date',
          render: ({ eta }) => renderISODate(eta as string, 'ASAP'),
          editComponent: ({ rowData: { start }, value, onChange }) => (
            <DatePicker
              autoOk
              clearable
              clearLabel="ASAP"
              emptyLabel="ASAP"
              format="dd/MM/yyyy"
              minDate={start}
              minDateMessage="Date should not be before start date"
              value={value || null}
              onChange={v => onChange(v?.toISODate())}
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          ),
        },
      ]}
      data={query =>
        user.tasks!.get().then(res => ({
          data: res.docs.map(dr => ({ id: dr.id, ...dr.data() })),
          page: 0,
          totalCount: res.docs.length,
        }))
      }
      editable={{
        onRowAdd: ({ id, ...extra }) =>
          user
            .tasks!.add({ start: DateTime.local().toISODate(), ...extra })
            .then(refreshTasks),
        onRowUpdate: ({ id, ...extra }) =>
          user
            .tasks!.doc(id)
            .set(extra, { merge: true })
            .then(refreshTasks),
        onRowDelete: ({ id }) =>
          user
            .tasks!.doc(id)
            .delete()
            .then(refreshTasks),
      }}
    />
  );
};

export const Tasks: React.FC = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/add`}>
        <AddTask />
      </Route>
      <Route path={`${match.path}/:taskUid`}>
        <TaskActionRoute />
      </Route>
      <Route path={match.path}>
        <TaskList />
      </Route>
    </Switch>
  );
};

const TaskActionRoute: React.FC = () => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/dismiss`}>
        <DismissTask />
      </Route>
      <Route path={`${match.path}/snooze`}>
        <SnoozeTask />
      </Route>
      <Route path={`${match.path}/unsnooze`}>
        <UnsnoozeTask />
      </Route>
      <Route path={match.path}>
        <Redirect to="/tasks" />
      </Route>
    </Switch>
  );
};
