import React, { useContext } from 'react';
import MaterialTable from 'material-table';
import { UserContext } from './auth';
import { TaskAction } from '../../shared/src/model';
export const Actions: React.FC = () => {
  const user = useContext(UserContext);
  return (
    <MaterialTable<TaskAction & { id: string }>
      options={{ search: false, paging: false }}
      title="Calendar actions performed"
      columns={[
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Date', field: 'date', type: 'date' },
        { title: 'Type', field: 'type' },
        { title: 'Task', field: 'task_id' },
        {
          title: 'Extras',
          render: ({ id, date, type, task_id, tableData, ...extras }: any) =>
            JSON.stringify(extras),
        },
      ]}
      data={query =>
        user.actions!.get().then(res => ({
          data: res.docs.map(dr => ({ id: dr.id, ...dr.data() })),
          page: 0,
          totalCount: res.docs.length,
        }))
      }
      editable={{
        onRowDelete: ({ id }) => user.actions!.doc(id).delete(),
      }}
    />
  );
};
