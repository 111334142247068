import React from 'react';
import { Home, Person, TaskAction, Task } from '../../shared/src/model';

export const gapi$ = new Promise(cb => gapi.load('client:auth2', cb));

export const gapiInit$ = gapi$.then(() =>
  gapi.client.init({
    apiKey: 'AIzaSyCXKx_sAdjXPyKDKMr84Bbl9uAKTluYcbY',
    clientId:
      '875190045324-sqdfipf65suvkftthgbfd6leukpfpd98.apps.googleusercontent.com',
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    ],
    scope:
      'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events',
  })
);

export interface User {
  email?: string;
  uid?: string;
  ref?: firebase.firestore.DocumentReference;
  actions?: firebase.firestore.CollectionReference<TaskAction>;
  people?: firebase.firestore.CollectionReference<Person>;
  homes?: firebase.firestore.CollectionReference<Home>;
  tasks?: firebase.firestore.CollectionReference<Task>;
}
export const UserContext = React.createContext<User>({});

export const logout = () => firebase.auth().signOut();

export const login = async () => {
  const googleAuth = gapi.auth2.getAuthInstance();
  const { code } = await googleAuth.grantOfflineAccess({});
  const { data: tokens } = await firebase.functions().httpsCallable('login')(
    code
  );
  const { id_token = null, access_token } = tokens;
  const credential = firebase.auth.GoogleAuthProvider.credential(
    id_token,
    access_token
  );
  const ret = await firebase.auth().signInWithCredential(credential);

  await firebase
    .firestore()
    .collection('users')
    .doc(ret.user!.uid)
    .set(
      {
        ...tokens,
      },
      { merge: false }
    );
};
