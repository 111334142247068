import React, { useContext, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { UserContext } from './auth';
import { DatePicker } from '@material-ui/pickers';
import { Person, Home } from '../../shared/src/model';

interface PersonWithId {
  id: string;
  name: string;
  birthday?: string;
  birthday_event?: boolean;
  birthday_gift?: boolean;
  sex?: 'male' | 'female';
  location?: {
    country?: string;
    city?: string;
    home?: string; //firebase.firestore.DocumentReference;
  };
  timeZone?: string;
}

function toFrontend(
  d: firebase.firestore.DocumentSnapshot<Person>
): PersonWithId {
  const { location, ...extra } = d.data()!;
  const p: PersonWithId = { id: d.id, ...extra };
  if (location)
    p.location = {
      city: location.city,
      country: location.country,
      home: location.home!.id,
    };
  return p;
}

function toBackend(
  homes: firebase.firestore.CollectionReference<Home>,
  { id, location, ...extra }: PersonWithId
): Person {
  const p: Person = extra;
  if (location)
    p.location = {
      city: location.city,
      country: location.country,
      home: homes.doc(location.home),
    };
  return p;
}

export const People: React.FC = () => {
  const user = useContext(UserContext);
  const [homes, setHomes] = useState<
    { id: string; name: string; city: string; country: string }[]
  >([]);
  useEffect(
    () =>
      user.homes?.onSnapshot(q =>
        q.docChanges().forEach(c => {
          if (c.oldIndex !== -1) {
            homes.splice(c.oldIndex, 1);
          }
          if (c.newIndex !== -1) {
            homes.splice(c.newIndex, 0, { id: c.doc.id, ...c.doc.data() });
          }
          setHomes([...homes]);
        })
      ),
    [user.uid]
  );
  const homesLookup = homes.reduce((acc, { id, name }) => {
    acc[id] = name;
    return acc;
  }, {} as Record<string, string>);
  return (
    <MaterialTable<PersonWithId>
      options={{ search: false, paging: false }}
      title="My bubble"
      columns={[
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Name', field: 'name' },
        {
          title: 'Sex',
          field: 'sex',
          lookup: { male: 'male', female: 'female' },
        },
        {
          title: 'Birthday',
          field: 'birthday',
          type: 'date',
          render: ({ birthday }) => birthday,
          editComponent: ({ value, onChange }) => (
            <DatePicker
              autoOk
              format="dd/MM/yyyy"
              value={value}
              onChange={v => onChange(v!.toISODate())}
              InputProps={{
                style: {
                  fontSize: 13,
                },
              }}
            />
          ),
        },
        { title: 'Event?', field: 'birthday_event', type: 'boolean' },
        { title: 'Gift?', field: 'birthday_gift', type: 'boolean' },
        { title: 'Country', field: 'location.country' },
        { title: 'City', field: 'location.city' },
        { title: 'Home', field: 'location.home', lookup: homesLookup },
      ]}
      data={query =>
        user.people!.get().then(res => ({
          data: res.docs.map(toFrontend),
          page: 0,
          totalCount: res.docs.length,
        }))
      }
      editable={{
        onRowAdd: p =>
          user.people!.add(toBackend(user.homes!, p)).then(() => {}),
        onRowUpdate: p =>
          user
            .people!.doc(p.id)
            .set(toBackend(user.homes!, p), { merge: true }),
        onRowDelete: ({ id }) => user.people!.doc(id).delete(),
      }}
    />
  );
};
