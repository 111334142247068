import React from 'react';

export interface Notif {
  msg: string;
  severity?: 'success' | 'info' | 'warning' | 'error';
  undo?: () => Promise<any>;
}

export const NotifContext = React.createContext<(n: Notif) => Promise<void>>(
  async () => {}
);
